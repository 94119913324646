import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { TimeRangeType } from '@shared/enums/time-range-type.enum';
import {
  currentSpotPricesDTO,
  SpotPriceHistoryItemDTO,
} from '@shared/models/orders/spot-price-history-list-model';
import { Currency } from '@shared/models/wallets/currency';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { IOrdersService } from '@shared/types/orders-service';
import { IProductsVM } from '@shared/types/products-vm';
import { IRatesVM } from '@shared/types/rates-vm';
import {
  DDMMYYYYFormat,
  getAMPMHoursFormat,
  getAMPMTimeFormatWithDate,
  getWeekDay,
  MDYFormat,
  MFormat,
  YFormat,
} from '@shared/utils/date';
import { getFirstMetalName } from '@shared/utils/products';
import { ChartData, ChartOptions } from 'chart.js';

interface PeriodItem {
  id: string;
  label: string;
}

export class MobileMarketViewModel {
  private productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
  private ordersService = appInject<IOrdersService>(DI_TOKENS.ordersService);
  private ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);

  private _currentPeriodId: TimeRangeType = TimeRangeType.Week;

  private _spotPriceData = {
    data: [] as SpotPriceHistoryItemDTO[],
  };
  private _prices = {
    data: {} as currentSpotPricesDTO,
  };
  private isMobile: boolean;

  constructor(isMobile: boolean) {
    this.isMobile = isMobile;
    appMakeObservable(this, {
      _spotPriceData: appObservable,
      _currentPeriodId: appObservable,
      _prices: appObservable,
    });
  }

  get prices() {
    return this._prices.data;
  }

  get currentMetal() {
    return getFirstMetalName(this.productsVM.products);
  }

  get currency(): Currency {
    return this.ratesVM.currency;
  }

  get currentPeriod(): TimeRangeType {
    return this._currentPeriodId;
  }

  private getTimeDateFormat(date: Date) {
    if (this._currentPeriodId === this.periodOptions[0]?.id) {
      return getAMPMHoursFormat(date);
    }

    if (this._currentPeriodId === this.periodOptions[1]?.id) {
      return getWeekDay(date, 'short');
    }

    if (this._currentPeriodId === this.periodOptions[2]?.id) {
      return DDMMYYYYFormat(date);
    }

    if (this._currentPeriodId === this.periodOptions[3]?.id) {
      return MFormat(date);
    }
    if (this._currentPeriodId === this.periodOptions[4]?.id) {
      return YFormat(date);
    }
    if (this._currentPeriodId === this.periodOptions[5]?.id) {
      return YFormat(date);
    }
    return MDYFormat(date);
  }

  getTooltipTimeDateFormat(date: Date) {
    return getAMPMTimeFormatWithDate(date);
  }

  get labels() {
    return this._spotPriceData.data.map((item) => {
      const d = new Date(item.date);
      return this.getTimeDateFormat(d);
    });
  }

  processLabel(index: number): string | null {
    switch (this._currentPeriodId) {
      case TimeRangeType.Day:
        return this.calculateLabelPoints(index, 8);
      case TimeRangeType.Week:
        return this.calculateLabelPoints(index, 6);
      case TimeRangeType.Month:
        return this.calculateLabelPoints(index, 10);
      case TimeRangeType.Year:
        return this.calculateLabelPoints(index, 60);
      case TimeRangeType.FiveYears:
        return this.calculateLabelPoints(index, 12);
      case TimeRangeType.TenYears:
        return this.calculateLabelPoints(index, 24);
      default:
        return null;
    }
  }

  private calculateLabelPoints(index: number, scaleFactor: number) {
    const item = this._spotPriceData.data[index];
    const offset = scaleFactor - 1;
    const isTooFewLabelsCount = this._spotPriceData.data.length / scaleFactor < 2;
    if (item) {
      const d = new Date(item.date);
      return index % scaleFactor === offset || (offset > 1 && isTooFewLabelsCount && index === 0)
        ? this.getTimeDateFormat(d)
        : null;
    } else {
      return null;
    }
  }

  get periodOptions(): Array<PeriodItem> {
    return [
      {
        id: TimeRangeType.Day,
        label: '1 D',
      },
      {
        id: TimeRangeType.Week,
        label: '1 W',
      },
      {
        id: TimeRangeType.Month,
        label: '1 M',
      },
      {
        id: TimeRangeType.Year,
        label: '1 Y',
      },
      {
        id: TimeRangeType.FiveYears,
        label: '5 Y',
      },
      {
        id: TimeRangeType.TenYears,
        label: '10 Y',
      },
    ];
  }

  get chartData(): ChartData {
    return {
      labels: this.labels,
      datasets: [
        this.isMobile
          ? {
              data: this._spotPriceData.data.map((item) => item.purchasePrice),
              borderColor: '#6A52FF',
              borderWidth: 2,
              showLine: true,
              pointRadius: 0,
              hoverBorderWidth: 2,
              pointHoverBorderColor: '#6A52FF',
              pointHoverBackgroundColor: '#0C0914',
              pointHoverBorderWidth: 2,
            }
          : {
              data: this._spotPriceData.data.map((item) => item.purchasePrice),
              borderColor: '#6A52FF',
              borderWidth: 4,
              showLine: true,
              pointRadius: 0,
              hoverBorderWidth: 2,
              pointHoverRadius: 8,
              pointHoverBorderColor: '#6A52FF',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderWidth: 4,
            },
      ],
    };
  }

  get desktopChartOption(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          padding: 15,
          external: this.createCustomTooltip(),
        },
      },
      interaction: {
        intersect: false,
      },
      scales: {
        xAxes: {
          ticks: {
            callback: (tick, index) => this.processLabel(index),
            padding: 10,
            color: '#BCC0C7',
            maxRotation: 30,
            autoSkip: false,
            align: 'center',
          },
        },
        yAxes: {
          ticks: {
            callback: (tick, index) => `$${minDigitsAfterDot(amountPipe(Number(tick)))}`,
            padding: 10,
            color: '#BCC0C7',
            maxRotation: 30,
            autoSkip: true,
            align: 'center',
          },
          grid: {
            display: true,
            color: 'rgba(255, 255, 255, 0.08)',
            borderWidth: 1,
            borderDash: [4, 6],
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: true,
          },
        },
      },
    };
  }

  get mobileChartOption(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          padding: 15,
          external: this.createCustomTooltip(),
        },
      },
      interaction: {
        intersect: false,
      },
      scales: {
        xAxes: {
          ticks: {
            callback: (tick, index) => this.processLabel(index),
            padding: 25,
            color: '#BCC0C7',
            maxRotation: 30,
            autoSkip: false,
          },
        },
        yAxes: {
          position: 'right',
          ticks: {
            autoSkip: false,
            padding: 10,
            precision: 1,
            color: '#BCC0C7',
            // @ts-ignore
            callback: (value: number) => minDigitsAfterDot(amountPipe(value)),
          },
          grid: {
            display: true,
            lineWidth: 1,
            color: 'rgba(255, 255, 255, 0.08)',
            borderWidth: 1,
            borderDash: [4, 6],
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
          },
        },
      },
    };
  }

  get chartOptions(): ChartOptions {
    return this.isMobile ? this.mobileChartOption : this.desktopChartOption;
  }

  getGrRate() {
    return this.prices.gr;
  }

  getOzRate() {
    return this.prices.oz;
  }

  getKgRate() {
    return this.prices.kg;
  }

  updatePeriod = (val: string) => {
    this._currentPeriodId = val as TimeRangeType;
  };

  fetchSpotPriceData = async () => {
    const currentPeriod = this.periodOptions.find((period) => period.id === this._currentPeriodId);
    if (currentPeriod) {
      const data = await this.ordersService.getHistoricalSpotPrices(
        this._currentPeriodId,
        this.currency,
        Currency.GSC,
      );

      this._spotPriceData.data = data.asJson.historicData.sort(
        (a, b) => Date.parse(a.date) - Date.parse(b.date),
      );
      this._prices.data = data.asJson.currentSpotPrices;
    }
  };

  private createCustomTooltip() {
    const getTooltipLabel = (item: SpotPriceHistoryItemDTO) => {
      const date = new Date(item.date);
      return getAMPMTimeFormatWithDate(date);
    };

    const getTooltipBodyValue = (item: SpotPriceHistoryItemDTO): string => {
      return `${minDigitsAfterDot(amountPipe(item.purchasePrice))}`;
    };

    const getOrCreateTooltip = (chart: any) => {
      let tooltipEl = chart.canvas.parentNode.querySelector('div');

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = '#1E1D36';
        tooltipEl.style.borderRadius = '6px';
        tooltipEl.style.color = 'rgba(255, 255, 255, 0.50)';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';
        tooltipEl.style.textAlign = 'left';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    return (context: { chart: any; tooltip: any }) => {
      const { chart, tooltip } = context;
      const tooltipEl = getOrCreateTooltip(chart);
      const dataIndex = tooltip.dataPoints?.[0]?.dataIndex || 0;

      const currentDataItem = this._spotPriceData.data[dataIndex] as SpotPriceHistoryItemDTO;
      const labelText = getTooltipLabel(currentDataItem);
      const bodyValueText = getTooltipBodyValue(currentDataItem);

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set Text
      if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b: { lines: any }) => b.lines);

        const tableHead = document.createElement('thead');
        const tableBody = document.createElement('tbody');

        titleLines.forEach(() => {
          const tr = document.createElement('tr');
          tr.style.fontSize = '11px';

          const th = document.createElement('th');
          const text = document.createTextNode(labelText);
          th.appendChild(text);
          tr.appendChild(th);
          tableHead.appendChild(tr);
        });

        bodyLines.forEach(() => {
          const currencyDiv = document.createElement('div');
          currencyDiv.style.marginTop = '5px';
          currencyDiv.style.marginRight = '5px';
          currencyDiv.style.fontSize = '11px';
          currencyDiv.style.color = '#ffffff';
          currencyDiv.style.display = 'inline';
          currencyDiv.style.alignItems = 'center';

          const currencyText = document.createTextNode(this.currency);
          currencyDiv.appendChild(currencyText);

          const valueText = document.createTextNode(bodyValueText);
          const valueDiv = document.createElement('div');
          valueDiv.style.marginTop = '5px';
          valueDiv.style.fontSize = '11px';
          valueDiv.style.color = '#ffffff';
          valueDiv.style.display = 'inline';
          valueDiv.appendChild(valueText);

          const tr = document.createElement('tr');
          const td = document.createElement('td');

          td.appendChild(currencyDiv);
          td.appendChild(valueDiv);
          tr.appendChild(td);
          tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');

        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }
        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + 12 + 'px';
      tooltipEl.style.top = positionY + tooltip.caretY + 12 + 'px';
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    };
  }
}
